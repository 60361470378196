import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Summer Grace" />

		<h2>Summer Grace</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST06 summer grace front.png"
			alt="Jesse Rivest - Summer Grace - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on August 19, 2021.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			Here's another song I wrote while living in Wellington, New Zealand.
			I was specifically living in Island Bay in a house up the hillside;
			I would sit out on the deck and close my eyes to the great view and imagine a scene set in the Okanagan Valley in Canada.
			I think this story was a longing for home and its summer, with a bit of romance thrown in for good measure.
			I recorded a demo of it on a little recorder and gave it to my friend Kyle te Kiwi, but that was as far as I went with it.
			I think he liked it; I think I liked it, too.
			I have always performed it since; it just never made it to an official recording.
			Until now.
		</p><p>
			After I improved the acoustics a bit in my Little Bakery Studio, I played with the idea of recording a double-tracked performance of the song; just me and the guitar.
			However, I couldn't do the second take while listening to the first take—too distracting and unnatural to play along with!
			So, I just played the second take blindly to the click track.
			I was impressed with how similar the two takes were—it was clear that my body had muscle memory of playing and singing this song.
			It was good enough for me; this would be the base of the song.
			From there I added some bass via piano and some electric guitar in the choruses. Then some Hammond B3, then some percussion.
			My partner, Jane, gave it a listen and felt it needed a little extra something to accompany me earlier on in the track;
			I came up with some distant piano counterpoint.
			That was it! Then some mixing, which took a while.
		</p><p>
			Like the previously released song, Silent, this song has some grammatical issues which I will mark up to poetic license.
			I recorded it pretty much as it originally was written; I don't think much changed along the years.
			I hope you enjoy it.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				Summer Grace (5:06)<br />
				T9012818949 - © Jesse Rivest 2008 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-21-00002 - © Jesse Rivest 2021 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST06, © Jesse Rivest 2021, All Rights Reserved<br />
			UPC/EAN: 0195999467146<br />
			Released: August 19, 2021
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Song written and sung by Jesse Rivest.<br />
			Acoustic guitar and electric guitar played by Jesse Rivest.<br />
			Programming of drums, synth pianos, and synth Hammond B3 by Jesse Rivest.<br />
			Album cover artwork by Lucas Pacífico.<br />
			Album cover layout by Jesse Rivest.<br />
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST06 summer grace back.png"
			alt="Jesse Rivest - Summer Grace - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h4>Video</h4>
		<div className="main--centerwrapper">
			<iframe
			className="main--centered"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/90qTotnvZa8"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />
		</div>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				Summer Grace</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2008
			</h5>
			<p>
				When they passed by that hot July<br />
				The blossoms were long ago fell<br />
				And then by moonlight they kept out of sight<br />
				And stole those cherries well
			</p>
			<p>
				Once at lakeside the moon did hide<br />
				Long enough to reveal enough<br />
				And as they dried they lay side by side<br />
				And dined on cherries in the buff<br />
				Yeah they dined on cherries in the buff
			</p>
			<p>
				She woke with a smile on her face<br />
				The lake and the sky had switched places<br />
				And the moon, it was diving with grace
			</p>
			<p>
				By mornings gone, the days drove on<br />
				And the nights repeats of the last<br />
				They left no trail, just a secret tail<br />
				Of the summer that the stone fruit crops were taxed<br />
				Yeah the summer that the stone fruit crops were taxed
			</p>
			<p>
				He woke with a smile on his face<br />
				The lake and the sky had switched places<br />
				And the moon, it was diving<br />
				While the stars, they were shining with grace
			</p>
			<p>
				She woke with a smile on her face<br />
				The lake and the sky had switched places<br />
				And the moon, it was diving<br />
				While the stars, they were shining<br />
				And their lives, they were thriving with grace
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
